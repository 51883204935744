import { createSelector } from 'reselect';

export const selectDocumentReducerState = state => state.document;

export const documentIsPrinting = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.isPrinting,
);

export const isTridionDocument = createSelector(
  selectDocumentReducerState,
  documentReducerState => documentReducerState.isTridion,
);
