import { createSlice } from '@reduxjs/toolkit';

export interface UserState {
  audience: string;
  email: string;
  firstName: string;
  fullName: string;
  language: string;
  country: { id?: string };
  locations: string[];
  permissions: { [key: string]: string[] };
  userId: string;
  userType: string;
  isGreatBritainUser: boolean;
  isLicensee: boolean;
}

const initialState: UserState = {
  audience: '',
  email: '',
  firstName: '',
  fullName: '',
  language: '',
  country: {},
  locations: [],
  permissions: {},
  userId: '',
  userType: '',
  isGreatBritainUser: false,
  isLicensee: false,
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initializeUser: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { initializeUser } = slice.actions;

export default slice.reducer;
