import Constants from 'constants/index';
import { generateTotalTimeFromSteps } from 'util/time';
import { getNameFromLanguage } from 'util/language';
import { arrayToCommaString } from 'util/arrayToCommaString';
import { arrayIntersect } from 'util/keepDuplicatesFromTwoArrays';
import { messageReactNative } from 'util/messageReactNative';
import { generateReport, printToNative } from 'util/generateReport';

import { mapCategoryToAliasTranslation } from 'util/categoryUtils';
import { convertDateForReports } from 'util/date';
import styled from 'styled-components';
import SearchFilterHeader from 'components/SearchFilterHeader/SearchFilterHeader';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setHeader } from 'store/header/slice';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components/dist/cjs';
import StickyFilterCard from 'components/StickyFilterCard/StickyFilterCard';
import CheckboxFilterSection from 'components/StickyFilterCard/CheckboxFilterSection';
import SortFilterHeader from 'components/SortFilterHeader/SortFilterHeader';
import {
  selectFilters,
  selectPagination,
  selectSearchFilter,
  selectSort,
  selectSortedAndFilteredAndPaginatedPlans,
} from 'store/managePlansFilter/selector';
import {
  useGetAssignableChecklistsQuery,
  useGetAssignedStatusQuery,
  useGetCourseReportQuery as useGetCourseReportQueryOld,
  useGetTeamMembersQuery,
  useGetReportsTeamMembersQuery,
  useGetOperatorsQuery as useGetOperatorsQueryOld,
} from 'services/pathwayApi';
import {
  addManagePlansCategoryFilter,
  clearManagePlansCheckboxFilters,
  clearManagePlansSearchFilter,
  loadMorePlans,
  removeManagePlansFilter,
  setManagePlans,
  setManagePlansSearchFilter,
  setManagePlansSort,
} from 'store/managePlansFilter/slice';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import {
  selectAllLocationsWithAtLeastLeaderPermissions,
  selectLocationsWithOperatorPermission,
  isUserLicensee,
} from 'store/user/selectors';
import LoadMorePaginator from 'components/LoadMorePaginator/LoadMorePaginator';
import FilterAndSortButton from 'components/FilterAndSortButton/FilterAndSortButton';
import CheckboxList from 'sharedComponents/app/CheckboxList';
import uniqBy from 'lodash/uniqBy';
import ReportsPlanCard from 'components/PlanCard/PlanCards/ReportsPlanCard';
import ReportsCompliancePlanCard from 'components/PlanCard/PlanCards/ReportsCompliancePlanCard';
import { NoMessage } from 'containers/TrainingPlans/ManagePlans/ManagePlanView';
import PrintReportModal from 'sharedComponents/app/popups/PrintReportModal';
import { withRoles } from 'sharedComponents/app/withRoles';
import {
  useGetAssignableChecklistsAndCoursesQuery,
  useGetOperatorsQuery,
  useGetUsersForLocationsQuery,
  useGetCourseReportQuery,
  useGetReportsTrainingPlanStatusesQuery,
  useGetStatusesAssignedToChecklistQuery,
} from 'services/xpApi';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  AssignableResponse,
  CourseReportDTO,
  OperatorDTO,
  PathwayUser,
  UserChecklistResultDTO,
  UserChecklistStatusResponse,
} from '@cfacorp-pathway/xp-api-typescript-client';

const ReportsPlansTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const { xpApi: xpApiFeatureFlag } = useFlags();
  const currentFilters: string[] = useSelector(selectFilters);
  const filteredAndSortedPlans: any[] = useSelector(
    selectSortedAndFilteredAndPaginatedPlans,
  );
  const isLicenseeUser = useSelector(isUserLicensee);
  const [showChooseReportLocationsPopup, setShowChooseReportLocationsPopup] =
    useState(false);
  const [showDoBChecked, setShowDoBChecked] = useState(false);
  const [noLocationsSelected, setNoLocationsSelected] = useState(false);
  const sort = useSelector(selectSort);

  const locationsWithAtLeastLeaderPermission = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );
  const locationsWithAtLeastOperatorPermission = useSelector(
    selectLocationsWithOperatorPermission,
  );
  const [selectedReportLocations, setSelectedReportLocations] = useState(
    locationsWithAtLeastLeaderPermission,
  );

  const searchFilter = useSelector(selectSearchFilter) ?? '';
  const { showing, total } = useSelector(selectPagination) as any;
  const isLeaderOrOperator =
    !!locationsWithAtLeastLeaderPermission.length ||
    !!locationsWithAtLeastOperatorPermission.length;
  const [triggerTeamMembersReportRefetch, setTriggerTeamMembersReportRefetch] =
    useState(false);
  const [triggerComplianceReportHandler, setTriggerComplianceReportHandler] =
    useState(false);
  const [planDetails, setPlanDetails] = useState({
    category: '',
    id: '',
    isCompliance: false,
    locations: [],
    name: '',
  });
  const [statusReport, setStatusReport] = useState<CourseReportDTO>({
    courseId: '',
    courseName: '',
    foodSafetyLetters: [],
    locations: [''],
    timeStamp: '',
    userData: [],
  });
  const [teamMembersReport, setTeamMembersReport] =
    useState<UserChecklistStatusResponse>({});

  const categoryLabels = {
    [Constants.PLAN_CATEGORIES.DEFAULT]: {
      translationString: t('TrainingPlans.planCategories.default'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.DEFAULT),
    },
    [Constants.PLAN_CATEGORIES.BACK_OF_HOUSE]: {
      translationString: t('TrainingPlans.planCategories.backOfHouse'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.BACK_OF_HOUSE),
    },
    [Constants.PLAN_CATEGORIES.COMPLIANCE]: {
      translationString: t('TrainingPlans.planCategories.compliance'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.COMPLIANCE),
    },
    [Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE]: {
      translationString: t('TrainingPlans.planCategories.frontOfHouse'),
      value: !!currentFilters.includes(
        Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE,
      ),
    },
    [Constants.PLAN_CATEGORIES.HOSPITALITY]: {
      translationString: t('TrainingPlans.planCategories.hospitality'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.HOSPITALITY),
    },
    [Constants.PLAN_CATEGORIES.LEADERSHIP]: {
      translationString: t('TrainingPlans.planCategories.leadership'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.LEADERSHIP),
    },
    [Constants.PLAN_CATEGORIES.ONBOARDING]: {
      translationString: t('TrainingPlans.planCategories.onboarding'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.ONBOARDING),
    },
  };

  if (isLicenseeUser) {
    delete categoryLabels?.[Constants.PLAN_CATEGORIES.COMPLIANCE];
  }

  const sortOptions = [
    {
      id: '1',
      translationString: t('TrainingPlans.filtering.newest'),
      value: Constants.PLANS_SORT_OPTIONS.NEWEST,
    },
    {
      id: '2',
      translationString: t('TrainingPlans.filtering.oldest'),
      value: Constants.PLANS_SORT_OPTIONS.OLDEST,
    },
    {
      id: '3',
      translationString: t('TrainingPlans.filtering.aToZ'),
      value: Constants.PLANS_SORT_OPTIONS.A2Z,
    },
    {
      id: '4',
      translationString: t('TrainingPlans.filtering.zToA'),
      value: Constants.PLANS_SORT_OPTIONS.Z2A,
    },
  ];

  // Plans

  const {
    data: oldUnOrderedPlans,
    isFetching: isFetchingPlansOld,
    isSuccess: isSuccessPlansOld,
  } = useGetAssignableChecklistsQuery(
    {},
    { skip: !!xpApiFeatureFlag, refetchOnMountOrArgChange: true },
  );

  const {
    data: xpUnOrderedPlans,
    isFetching: isFetchingPlansXp,
    isSuccess: isSuccessPlansXp,
  } = useGetAssignableChecklistsAndCoursesQuery(
    { locations: undefined },
    {
      skip: !xpApiFeatureFlag,
      refetchOnMountOrArgChange: true,
    },
  );

  const isSuccess: boolean = xpApiFeatureFlag
    ? isSuccessPlansXp
    : isSuccessPlansOld;

  const isFetching: boolean = xpApiFeatureFlag
    ? isFetchingPlansXp
    : isFetchingPlansOld;

  const unOrderedPlans: AssignableResponse = xpApiFeatureFlag
    ? xpUnOrderedPlans
    : oldUnOrderedPlans;

  const { data: oldOperators } = useGetOperatorsQueryOld(
    {},
    { skip: !!xpApiFeatureFlag },
  );

  const { data: xpOperators } = useGetOperatorsQuery(undefined, {
    skip: !xpApiFeatureFlag,
  });

  const operators: OperatorDTO[] = xpApiFeatureFlag
    ? xpOperators
    : oldOperators;

  const operatorsWhereUserIsLeader = operators?.filter(operator =>
    operator?.locations?.some(operatorLocation =>
      locationsWithAtLeastLeaderPermission.includes(operatorLocation),
    ),
  );

  // Team Members (for Plan Card)
  const { data: oldAllTeamMembersData } = useGetTeamMembersQuery(
    {
      locations: locationsWithAtLeastLeaderPermission,
    },
    { skip: !!xpApiFeatureFlag, refetchOnMountOrArgChange: true },
  );
  const { data: xpAllTeamMembersData } = useGetUsersForLocationsQuery(
    { locations: locationsWithAtLeastLeaderPermission },
    { skip: !xpApiFeatureFlag },
  );

  const allTeamMembersData: PathwayUser[] = xpApiFeatureFlag
    ? xpAllTeamMembersData
    : oldAllTeamMembersData;

  const {
    data: oldStatusReportData,
    isFetching: oldStatusReportIsFetching,
    isSuccess: oldIsStatusReportSuccess,
  } = useGetCourseReportQueryOld(
    {
      courseId: planDetails.id,
      location: selectedReportLocations,
    },
    {
      skip:
        !planDetails.isCompliance ||
        !planDetails.id ||
        !isLeaderOrOperator ||
        !selectedReportLocations.length ||
        !!xpApiFeatureFlag,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: xpApiStatusReportData,
    isFetching: xpApiStatusReportIsFetching,
    isSuccess: xpApiIsStatusReportSuccess,
  } = useGetCourseReportQuery(
    {
      courseId: planDetails.id,
      locations: selectedReportLocations,
    },
    {
      skip:
        !planDetails.isCompliance ||
        !planDetails.id ||
        !isLeaderOrOperator ||
        !selectedReportLocations.length ||
        !xpApiFeatureFlag,
      refetchOnMountOrArgChange: true,
    },
  );

  const statusReportData: CourseReportDTO = xpApiFeatureFlag
    ? xpApiStatusReportData
    : oldStatusReportData;
  const statusReportIsFetching: boolean = xpApiFeatureFlag
    ? xpApiStatusReportIsFetching
    : oldStatusReportIsFetching;
  const isStatusReportSuccess: boolean = xpApiFeatureFlag
    ? xpApiIsStatusReportSuccess
    : oldIsStatusReportSuccess;

  // Team Members
  const {
    data: oldReportsTeamMembersData,
    isFetching: oldReportsTeamMembersIsFetching,
    isSuccess: oldIsReportsTeamMembersDataSuccess,
    refetch: oldRefetchReportsTeamMembers,
  } = useGetReportsTeamMembersQuery(
    {},
    {
      skip: !!xpApiFeatureFlag,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: xpReportsTeamMemberStatuses,
    isFetching: xpReportsTeamMembersStatusesIsFetching,
    isSuccess: xpReportsTeamMembersStatusesIsSuccess,
    refetch: refetchReportsTeamMembersStatuses,
  } = useGetReportsTrainingPlanStatusesQuery(
    {
      locations: [],
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !xpApiFeatureFlag,
    },
  );

  const reportsTeamMembersData: UserChecklistStatusResponse = xpApiFeatureFlag
    ? xpReportsTeamMemberStatuses
    : oldReportsTeamMembersData;
  const reportsTeamMembersIsFetching = xpApiFeatureFlag
    ? xpReportsTeamMembersStatusesIsFetching
    : oldReportsTeamMembersIsFetching;
  const isReportsTeamMembersDataSuccess = xpApiFeatureFlag
    ? xpReportsTeamMembersStatusesIsSuccess
    : oldIsReportsTeamMembersDataSuccess;

  const {
    data: oldAssignedTeamMembersList,
    isFetching: oldAssignedTeamMembersListIsFetching,
    isSuccess: oldIsAssignedTeamMembersListSuccess,
  } = useGetAssignedStatusQuery(
    {
      checklist: planDetails.id,
      location: locationsWithAtLeastLeaderPermission,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !planDetails.id || planDetails.isCompliance || !xpApiFeatureFlag,
    },
  );

  const {
    data: xpAssignedTeamMembersList,
    isFetching: xpAssignedTeamMembersListIsFetching,
    isSuccess: xpIsAssignedTeamMembersListSuccess,
  } = useGetStatusesAssignedToChecklistQuery(
    {
      checklistId: planDetails.id,
      locations: locationsWithAtLeastLeaderPermission,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !planDetails.id || planDetails.isCompliance || !xpApiFeatureFlag,
    },
  );

  const assignedTeamMembersList: UserChecklistResultDTO = xpApiFeatureFlag
    ? xpAssignedTeamMembersList
    : oldAssignedTeamMembersList;
  const assignedTeamMembersListIsFetching: boolean = xpApiFeatureFlag
    ? xpAssignedTeamMembersListIsFetching
    : oldAssignedTeamMembersListIsFetching;
  const isAssignedTeamMembersListSuccess: boolean = xpApiFeatureFlag
    ? xpIsAssignedTeamMembersListSuccess
    : oldIsAssignedTeamMembersListSuccess;

  useEffect(() => {
    dispatch(setHeader(t('Generic.reports')));
  }, [dispatch, t]);

  // Set Plans
  useEffect(() => {
    if (isSuccess) {
      const storePlansWithName = unOrderedPlans?.checklists?.map(plan => {
        return {
          ...plan,
          planName: getNameFromLanguage(plan?.checklist?.name).toLowerCase(),
        };
      });
      if (isLicenseeUser) {
        dispatch(setManagePlans({ plans: storePlansWithName ?? [] }));
      } else {
        dispatch(
          setManagePlans({
            plans:
              storePlansWithName?.concat(unOrderedPlans?.courses as any) ?? [],
          }),
        );
      }
    }
  }, [dispatch, isLicenseeUser, isSuccess, unOrderedPlans]);

  useEffect(() => {
    if (isStatusReportSuccess && !statusReportIsFetching) {
      setStatusReport(statusReportData);
    }
  }, [isStatusReportSuccess, statusReportData, statusReportIsFetching]);

  useEffect(() => {
    if (isReportsTeamMembersDataSuccess) {
      setTeamMembersReport(reportsTeamMembersData);
    }
  }, [isReportsTeamMembersDataSuccess, reportsTeamMembersData]);

  useEffect(() => {
    if (triggerTeamMembersReportRefetch) {
      !!xpApiFeatureFlag
        ? refetchReportsTeamMembersStatuses()
        : oldRefetchReportsTeamMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isStatusReportSuccess,
    xpApiFeatureFlag,
    reportsTeamMembersData,
    triggerTeamMembersReportRefetch,
  ]);

  const onPrintReport = ({
    category,
    id,
    isCompliancePlan,
    locations,
    planName,
  }) => {
    setPlanDetails({
      category,
      id,
      isCompliance: isCompliancePlan,
      locations,
      name: planName,
    });
    if (isCompliancePlan) {
      if (planName.includes('Food Safety') || locations?.length > 1) {
        setShowChooseReportLocationsPopup(true);
      } else {
        // need to wait for setPlanDetails to populate the state :(
        setTriggerComplianceReportHandler(true);
      }
    } else if (locations?.length > 1) {
      setShowChooseReportLocationsPopup(true);
    } else {
      // We don't have the data to print yet so we need to refetch first
      setTriggerTeamMembersReportRefetch(true);
    }
  };

  const getComplianceReportToPrint = () => {
    return [...statusReport?.userData!]
      .sort((teamMemberA, teamMemberB) => {
        return teamMemberA?.firstName!.localeCompare(teamMemberB?.firstName!);
      })
      .map(teamMember => {
        return {
          [Constants.REPORT_TABLE
            .NAME]: `${teamMember.firstName} ${teamMember.lastName}`,
          [Constants.REPORT_TABLE.DATE_OF_BIRTH]: teamMember.birthDate
            ? teamMember.birthDate
            : t('Generic.na'),
          [Constants.REPORT_TABLE.COMPLETION_DATE]:
            teamMember.courseCompletionDate
              ? convertDateForReports(teamMember.courseCompletionDate)
              : t('Generic.notInCompliance'),
          [Constants.REPORT_TABLE.EXPIRATION_DATE]: teamMember?.certificate
            ?.expiration
            ? convertDateForReports(teamMember.certificate.expiration)
            : t('Generic.na'),
        };
      });
  };

  const reportData = uniqBy(
    teamMembersReport?.users
      ?.map(teamMember => {
        return {
          ...teamMember.user,
          plansAssigned: teamMember.plansAssigned,
          plansCompleted: teamMember.plansCompleted,
        };
      })
      ?.filter(teamMember1 => {
        return assignedTeamMembersList
          ?.status!.filter(
            teamMember =>
              teamMember.status !== Constants.TRAINING_PLANS.UNASSIGNED,
          )
          .some(teamMember2 => teamMember1.adId === teamMember2.userId);
      })
      .map(user => {
        return {
          ...user,
          completionDate: assignedTeamMembersList?.status?.find(
            teamMember => teamMember?.userId === user?.adId,
          )?.completionDate,
          status: assignedTeamMembersList?.status?.find(
            teamMember => teamMember.userId === user.adId,
          )?.status,
          steps: assignedTeamMembersList?.status?.find(
            teamMember => teamMember.userId === user.adId,
          )?.steps,
        };
      }),
    'adId',
  );

  const getStoreReportToPrint = () => {
    return uniqBy(
      reportData.filter(teamMember =>
        planDetails?.locations?.length > 1
          ? teamMember?.locations.some(location =>
              selectedReportLocations.includes(location),
            )
          : teamMember,
      ),
      'adId',
    )
      .sort((teamMemberA, teamMemberB) => {
        return teamMemberA.name.localeCompare(teamMemberB.name);
      })
      .map(teamMember => {
        return {
          [Constants.REPORT_TABLE.NAME]: teamMember.name,
          [Constants.REPORT_TABLE.ITEMS_COMPLETED]: `${
            assignedTeamMembersList?.status?.find(
              userStatus => userStatus.userId === teamMember.adId,
            )?.stepsComplete || 0
          }/${assignedTeamMembersList?.checklist?.stepsTotal}`,
          [Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN]:
            generateTotalTimeFromSteps(
              teamMember.steps,
              t('Generic.hour'),
              t('Generic.mins'),
            ),
          [Constants.REPORT_TABLE.STATUS]: t(
            `TrainingPlans.statusOptions.${
              Constants.TRAINING_PLANS_STATUSES[teamMember.status]
            }`,
          ),
          [Constants.REPORT_TABLE.COMPLETION_DATE]: teamMember.completionDate
            ? convertDateForReports(teamMember.completionDate)
            : t('Generic.na'),
          id: assignedTeamMembersList?.checklist?.id,
        };
      });
  };

  //eslint-disable-next-line
  const tableHead = planDetails.isCompliance
    ? [
        [
          Constants.REPORT_TABLE.NAME,
          Constants.REPORT_TABLE.COMPLETION_DATE,
          Constants.REPORT_TABLE.EXPIRATION_DATE,
        ],
      ]
    : [
        [
          Constants.REPORT_TABLE.NAME,
          Constants.REPORT_TABLE.ITEMS_COMPLETED,
          Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN,
          Constants.REPORT_TABLE.STATUS,
          Constants.REPORT_TABLE.COMPLETION_DATE,
        ],
      ];
  if (showDoBChecked) {
    tableHead?.[0]?.splice(1, 0, Constants.REPORT_TABLE.DATE_OF_BIRTH);
  }

  //eslint-disable-next-line
  const getReportToGenerate = () => {
    return {
      category: t(mapCategoryToAliasTranslation(planDetails?.category ?? '')),
      foodSafetyImage:
        statusReport?.foodSafetyLetters?.[0]?.foodSafetyLetter?.content,
      id: planDetails?.id,
      location: `${t('Generic.at')} ${
        !selectedReportLocations.length && planDetails?.locations
          ? planDetails?.locations.toString()
          : arrayToCommaString(selectedReportLocations, t('Generic.and'))
      }`,
      name: planDetails?.name,
      reportToPrint: planDetails.isCompliance
        ? getComplianceReportToPrint().map(teamMember =>
            tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
          )
        : getStoreReportToPrint().map(teamMember =>
            tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
          ),
      tableHead,
    };
  };

  //eslint-disable-next-line
  const getMessageToSend = report => {
    return {
      category: report.category,
      location: report.location,
      name: report.name,
      reportToPrint: planDetails.isCompliance
        ? getComplianceReportToPrint()
        : getStoreReportToPrint(),
      tableHead,
    };
  };

  /** If a report does not have a location screen, we need to wait until the data is
   * returned from the query.  Once we have the data from the backend, we can then
   * show the printable report.
   */
  useEffect(() => {
    if (
      triggerTeamMembersReportRefetch &&
      isReportsTeamMembersDataSuccess &&
      isAssignedTeamMembersListSuccess &&
      !assignedTeamMembersListIsFetching &&
      !reportsTeamMembersIsFetching
    ) {
      const reportToGenerate = getReportToGenerate();
      const messageToSend = getMessageToSend(reportToGenerate);
      generateReport(reportToGenerate);
      messageReactNative(
        Constants.RN_MESSAGE_TYPES.PRINT,
        printToNative(messageToSend),
      );
      setTriggerTeamMembersReportRefetch(false);
      resetPlanState();
    }
  }, [
    assignedTeamMembersListIsFetching,
    getMessageToSend,
    getReportToGenerate,
    isAssignedTeamMembersListSuccess,
    isReportsTeamMembersDataSuccess,
    reportsTeamMembersIsFetching,
    triggerTeamMembersReportRefetch,
  ]);

  const handleCurrentFilteredLocations = useCallback(() => {
    setSelectedReportLocations(locationsWithAtLeastLeaderPermission);
    if (!!noLocationsSelected) {
      setNoLocationsSelected(false);
    }
    setShowDoBChecked(false);
  }, [locationsWithAtLeastLeaderPermission, noLocationsSelected]);

  const resetPlanState = () => {
    setPlanDetails({
      category: '',
      id: '',
      isCompliance: false,
      locations: [],
      name: '',
    });
    setStatusReport({
      courseId: '',
      courseName: '',
      foodSafetyLetters: [],
      locations: [''],
      timeStamp: '',
      userData: [],
    });
  };

  const handlePrintReport = useCallback(() => {
    if (
      !selectedReportLocations.length &&
      locationsWithAtLeastLeaderPermission?.length > 1
    ) {
      setNoLocationsSelected(true);
    } else {
      const reportToGenerate = getReportToGenerate();
      const messageToSend = getMessageToSend(reportToGenerate);
      setShowChooseReportLocationsPopup(false);
      generateReport(reportToGenerate);
      messageReactNative(
        Constants.RN_MESSAGE_TYPES.PRINT,
        printToNative(messageToSend),
      );
      handleCurrentFilteredLocations();
      resetPlanState();
    }
  }, [
    getMessageToSend,
    getReportToGenerate,
    handleCurrentFilteredLocations,
    locationsWithAtLeastLeaderPermission?.length,
    selectedReportLocations.length,
  ]);

  useEffect(() => {
    if (
      !!planDetails.id &&
      !!statusReport.courseId &&
      !!triggerComplianceReportHandler &&
      !!isStatusReportSuccess &&
      !statusReportIsFetching
    ) {
      handlePrintReport();
      setTriggerComplianceReportHandler(false);
    }
  }, [
    handlePrintReport,
    isStatusReportSuccess,
    planDetails.id,
    statusReport.courseId,
    statusReportIsFetching,
    triggerComplianceReportHandler,
  ]);

  const handleShowDoBCheckbox = () => {
    setShowDoBChecked(!showDoBChecked);
  };

  const onPrintReportCancel = () => {
    setShowChooseReportLocationsPopup(false);
    handleCurrentFilteredLocations();
    resetPlanState();
  };

  return (
    <>
      <StyledContent>
        <SearchFilterHeader
          onChange={e =>
            dispatch(
              setManagePlansSearchFilter({ searchFilter: e.target.value }),
            )
          }
          onClear={() => dispatch(clearManagePlansSearchFilter())}
          searchPlaceholder={t('Reports.filtering.searchPlans')}
          searchValue={searchFilter}
          title={t('Reports.tabPlans')}
        />
        {!!isSmAndDown && !isFetching && (
          <FilterAndSortButton
            onSortChange={option => {
              dispatch(setManagePlansSort({ sort: option.value }));
            }}
            sortOptions={sortOptions}
            sortValue={sortOptions?.find(option => option.value === sort)}
            text={`${t('TrainingPlans.filtering.show')} ${
              filteredAndSortedPlans?.length ?? 0
            } ${t('TrainingPlans.filtering.results')}`}
          >
            <CheckboxFilterSection
              labels={categoryLabels}
              onChange={value => {
                if (!!currentFilters.includes(value)) {
                  dispatch(removeManagePlansFilter({ filter: value }));
                } else {
                  dispatch(addManagePlansCategoryFilter({ filter: value }));
                }
              }}
              title={t('Browse.categories')}
            />
          </FilterAndSortButton>
        )}
        <PlansList>
          {!isSmAndDown && !isFetching && (
            <StickyFilterCard>
              <CheckboxFilterSection
                labels={categoryLabels}
                onChange={value => {
                  if (!!currentFilters.includes(value)) {
                    dispatch(removeManagePlansFilter({ filter: value }));
                  } else {
                    dispatch(addManagePlansCategoryFilter({ filter: value }));
                  }
                }}
                title={t('Browse.categories')}
              />
            </StickyFilterCard>
          )}
          <PlansContainer>
            <LoadingOverlay isOpen={isFetching} />
            <PlanCardsList>
              {!!filteredAndSortedPlans?.length && !isFetching && (
                <>
                  <SortFilterHeader
                    label={t('TrainingPlans.filtering.sortBy')}
                    onChange={option => {
                      dispatch(setManagePlansSort({ sort: option.value }));
                    }}
                    onClear={() => {
                      dispatch(clearManagePlansCheckboxFilters());
                    }}
                    options={sortOptions}
                    showClear={false}
                    text={`${total ?? 0} ${t('Reports.tabPlans')}`}
                    value={sortOptions?.find(option => option.value === sort)}
                  />
                  <ReportsCardContainer>
                    {filteredAndSortedPlans.map(plan =>
                      plan?.checklist ? (
                        <ReportsPlanCard
                          assignedUsers={plan?.assignedUsers}
                          completedUsers={plan?.completedUsers}
                          data-testid="ReportsPlanCard"
                          key={plan?.checklist?.id}
                          locations={
                            // intersects locations where user is a leader with the plans locations
                            arrayIntersect(
                              operatorsWhereUserIsLeader?.find(
                                operator =>
                                  operator.id === plan?.checklist?.ownerId,
                              )?.locations ?? [],
                              locationsWithAtLeastLeaderPermission,
                            )
                          }
                          onPrintReport={({ category, id, locations }) =>
                            onPrintReport({
                              category,
                              id,
                              isCompliancePlan: false,
                              locations,
                              planName: getNameFromLanguage(
                                plan?.checklist?.name,
                              ),
                            })
                          }
                          plan={plan?.checklist}
                        />
                      ) : (
                        <ReportsCompliancePlanCard
                          assignedUsers={plan?.assignedUsers}
                          completedUsers={plan?.completedUsers}
                          course={{
                            courseID: plan?.courseID,
                            courseName: plan?.courseName,
                            createdDate: plan?.createdDate,
                            enabled: plan?.enabled,
                            id: plan?.id,
                          }}
                          data-testid="ReportsCompliancePlanCard"
                          enrollments={plan?.enrollments}
                          key={plan.courseID}
                          locations={locationsWithAtLeastLeaderPermission}
                          onPrintReport={({ category, id, locations }) =>
                            onPrintReport({
                              category,
                              id,
                              isCompliancePlan: true,
                              locations,
                              planName: getNameFromLanguage(plan?.name),
                            })
                          }
                          totalTeamMembers={
                            allTeamMembersData.filter(
                              (value, innerIndex, self) =>
                                innerIndex ===
                                self.findIndex(
                                  user => user.adId === value.adId,
                                ),
                            ).length
                          }
                        />
                      ),
                    )}
                  </ReportsCardContainer>
                  <LoadMorePaginator
                    onClick={() => dispatch(loadMorePlans())}
                    showing={showing}
                    showingText={t('TrainingPlans.showingXOfYPlans', {
                      showing,
                      total: total ?? 0,
                    })}
                    total={total ?? 0}
                  />
                </>
              )}
              {!filteredAndSortedPlans?.length &&
                !isFetching &&
                !currentFilters?.length &&
                !searchFilter && (
                  <NoMessage message={<Trans i18nKey={'Reports.noPlans'} />} />
                )}
              {(!!currentFilters?.length || !!searchFilter) && !total && (
                <NoMessage message={t('TrainingPlans.noPlansResults')} />
              )}
            </PlanCardsList>
          </PlansContainer>
        </PlansList>
      </StyledContent>
      <PrintReportModal
        bodyText={t('TrainingPlans.chooseWhatToInclude')}
        children={
          !planDetails?.isCompliance
            ? planDetails?.locations?.map((id, idx) => (
                <StyledCheckboxList
                  id={id}
                  idx={idx}
                  key={idx}
                  selectedLocations={selectedReportLocations}
                  setSelectedLocations={setSelectedReportLocations}
                />
              ))
            : // Compliance plans
            locationsWithAtLeastLeaderPermission.length > 1
            ? locationsWithAtLeastLeaderPermission.map((id, idx) => (
                <StyledCheckboxList
                  id={id}
                  idx={idx}
                  key={idx}
                  selectedLocations={selectedReportLocations}
                  setSelectedLocations={setSelectedReportLocations}
                />
              ))
            : null
        }
        handleShowDoBCheckbox={handleShowDoBCheckbox}
        headerText={t('Generic.printReport')}
        isFoodSafety={planDetails?.name?.includes('Food Safety')}
        isOpen={showChooseReportLocationsPopup}
        noLocationsSelected={noLocationsSelected}
        onClose={onPrintReportCancel}
        primaryButtonHandler={handlePrintReport}
        primaryButtonText={t('Button.print')}
        secondaryButtonHandler={onPrintReportCancel}
        secondaryButtonText={t('Button.cancel')}
        selectedReportLocations={selectedReportLocations}
        showDoBChecked={showDoBChecked}
      />
    </>
  );
};

const ReportsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const PlansList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

const PlanCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const PlansContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const StyledCheckboxList = styled(CheckboxList)`
  padding: 6px 0;
`;

export default withRoles(
  ReportsPlansTab,
  [],
  [Constants.USER_RESTRICTIONS.GREAT_BRITAIN_USER],
);
