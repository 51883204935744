import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'document',
  initialState: {
    isPrinting: false,
    isTridion: false,
  },
  reducers: {
    setIsPrinting: (state, action) => {
      state.isPrinting = action.payload.isPrinting;
    },
    setIsTridion: (state, action) => {
      state.isTridion = action.payload.isTridion;
    },
  },
});

export const { setIsPrinting, setIsTridion } = slice.actions;

export default slice.reducer;
